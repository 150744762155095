
import { computed, reactive, toRefs } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

import { IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/vue';

import { chevronBackOutline } from 'ionicons/icons';

export default {
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar
  },
  setup(): any {
    const store = useStore();
    const route = useRoute();

    const title = computed(() => {
      switch (route.params.type) {
        case 'problems':
          return 'Problems';
        case 'solutions':
          return 'Solutions';
        default:
          return 'Resources';
      }
    });

    const type = computed(() => {
      switch (route.params.type) {
        case 'problems':
          return 'content/wpSleepProblems';
        case 'solutions':
          return 'content/wpSleepSolutions';
        default:
          return 'content/wpSleepResources';
      }
    });

    const page = reactive({
      parentSlug: route.params.type,
      currentSlug: computed(() => route.params.slug || 'home'),
      currentPage: computed(() => {
        let pages = store.getters[type.value].filter(p => p.slug === page.currentSlug);

        if (pages.length) {
          return pages[0];
        }

        return {
          title: '',
          content: '',
          related: null,
          html: ''
        };
      }),
      title,
      html: computed(() => page?.currentPage?.content?.rendered || ''),
      pageHTML: computed(() => {
        return page.html.replace(/data-original=/gi, 'src=');
      }),
      relatedContent: computed(() => {
        return page?.currentPage?.related;
      })
    });

    return {
      chevronBackOutline,
      ...toRefs(page)
    };
  }
};
