<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <router-link :to="`/${parentSlug}`" class="back-button" title="back">
            <ion-icon slot="icon-only" color="tetranary" :icon="chevronBackOutline"></ion-icon>
          </router-link>
        </ion-buttons>
        <ion-title color="primary">
          <h2 class="mb-0 ion-text-center">{{ title }}</h2>
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :scroll-events="true" color="light">
      <div id="wp-content" v-html="pageHTML" class="wp-content"></div>
      <div class="wp-content" v-if="relatedContent && relatedContent.length">
        <h2>See also:</h2>
        <ul>
          <li v-for="item in relatedContent" :key="item.slug">
            <router-link :to="`/${item.slug}`" class="text-black">
              {{ item.title }}
            </router-link>
          </li>
        </ul>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { computed, reactive, toRefs } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

import { IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/vue';

import { chevronBackOutline } from 'ionicons/icons';

export default {
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar
  },
  setup(): any {
    const store = useStore();
    const route = useRoute();

    const title = computed(() => {
      switch (route.params.type) {
        case 'problems':
          return 'Problems';
        case 'solutions':
          return 'Solutions';
        default:
          return 'Resources';
      }
    });

    const type = computed(() => {
      switch (route.params.type) {
        case 'problems':
          return 'content/wpSleepProblems';
        case 'solutions':
          return 'content/wpSleepSolutions';
        default:
          return 'content/wpSleepResources';
      }
    });

    const page = reactive({
      parentSlug: route.params.type,
      currentSlug: computed(() => route.params.slug || 'home'),
      currentPage: computed(() => {
        let pages = store.getters[type.value].filter(p => p.slug === page.currentSlug);

        if (pages.length) {
          return pages[0];
        }

        return {
          title: '',
          content: '',
          related: null,
          html: ''
        };
      }),
      title,
      html: computed(() => page?.currentPage?.content?.rendered || ''),
      pageHTML: computed(() => {
        return page.html.replace(/data-original=/gi, 'src=');
      }),
      relatedContent: computed(() => {
        return page?.currentPage?.related;
      })
    });

    return {
      chevronBackOutline,
      ...toRefs(page)
    };
  }
};
</script>
